import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function useAlternative() {
  const location = useLocation();
  const version = getQueryValue(location, 'alt');

  return version === '2';
}

export default useAlternative;

import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { BOY_NAME_SHORT, BOY_NAME_SHORT2, GIRL_NAME_SHORT, GIRL_NAME_SHORT2 } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';

import useInvitation from '@hooks/useInvitation';
import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';
import useAlternative from '@hooks/useAlternative';
// const TEXT_SHADOW = 'rgba(12,12,12,.8)';

function LeftContent() {
  const isInvitation = useInvitation();
  const isAlternative = useAlternative();
  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      bgPos="center"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
    >
      <Box bgColor={BG_SECONDARY_TRANSPARENT} height="100%" padding="42px">
        <WithAnimation>
          <Text color="white" letterSpacing="2px" fontSize="md" fontWeight="light">
            {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
          </Text>
        </WithAnimation>
        <Box
          padding="32px 0"
          textColor="secondaryColorText"
          textTransform="uppercase"
          letterSpacing="4px"
        >
          <Heading fontWeight="normal" size="4xl">
            {isAlternative ? GIRL_NAME_SHORT2 : GIRL_NAME_SHORT} &
          </Heading>
          <Heading fontWeight="normal" size="4xl" marginTop="-18px" marginLeft="12px">
            {isAlternative ? BOY_NAME_SHORT2 : BOY_NAME_SHORT}
          </Heading>
        </Box>
        <WithAnimation>
          <Text
            color="white"
            fontWeight="light"
            fontStyle="italic"
            maxWidth="800px"
            letterSpacing="1px"
          >
            {`"What greater thing is there for two human souls, than to feel that they are joined for life–to strengthen each other in all labour, to rest on each other in all sorrow, to minister to each other in all pain, to be one with each other in silent unspeakable memories at the moment of the last parting?"`}
            <br />— George Eliot, Adam Bede
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;

export const BG_COVER = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/coverRev.jpg?updatedAt=1697091216368`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/background%20foto%20awal.jpg?updatedAt=1695987909293`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
export const BG_IG_FILTER = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/COVER%20KEZIA%20&%20NICOLAS.jpg?updatedAt=1697534424810`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/DIT_7125.jpg?updatedAt=1695987909509`;

export const IMG_AYAT = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/DIT_7788.jpg?updatedAt=1695987909473`;
export const IMG_LOGO = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/Monogram.png?updatedAt=1695990035902`;

export const IMG_MAN = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/foto%20portrait%20nicolas54.jpg?updatedAt=1696226446919`;
export const IMG_GIRL = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/foto%20portrait%20kezia542.jpg?updatedAt=1696226570678`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

export const IMG_PHOTO_1 = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/coverGaleri1Rev2.jpg?updatedAt=1697092941838`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/coverGaleri2Rev.jpg?updatedAt=1697092779530`;
export const IMG_PHOTO_CONTENT = `https://ik.imagekit.io/q9egoy1h4/KeziaNicolas/DIT_7125.jpg?updatedAt=1695987909509`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
